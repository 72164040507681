import {showPrice, showRoundAmount, showPercent, toUsdc, showAmount} from "../../libs/util";

const Component = ({product, targetCurrency, sourceCurrency, expiredAmount, exercisedAmount}) => {
    return (
        <>

                <div className="p-2">
                    <div className="nk-ivp-summary card-text">
                        <div className="row">
                            <div className="col-6 border-right">
                                <span className="lead-text">
                                    <span className=" text-dark extra-dark">{(product) ? showPercent(product.calc_adj_apy,2) : "-"}</span>
                                </span>
                                <span className="sub-text">Annualised Return (APR)</span>
                            </div>
                            <div className="col-6">
                                <span className="lead-text">

                                    <span className="text-dark extra-dark">{(product) ? product.tenor : "-"}</span>

                                </span>
                                <span className="sub-text">Days</span>
                            </div>
                        </div>
                        <div className="card-title text-center pt-2 mt-2 border-top">
                            <span className="title form-label">Indicative Return on Maturity Date</span>
                        </div>
                        <div className="row">
                            <div className="col-6 border-right">
                                <span className="lead-text"><span 
                                    className=" text-dark extra-small">{(expiredAmount) ? showRoundAmount(expiredAmount, toUsdc(sourceCurrency)) : 0}</span></span>
                                <span className="lead-text"><span
                                    className=" text-dark extra-small">{(sourceCurrency) ? toUsdc(sourceCurrency) : "-"}</span></span>
                                {product != null && (
                                <span className="sub-text">If {product.option.quote_currency}/USD {(product.option.option_type == 'PUT') ? String.fromCharCode(8805) : String.fromCharCode(8804)} {showAmount(product.option.strike_price, sourceCurrency)}</span>
                                )}
                                {(!product) && (
                                    <span className="sub-text">-</span>
                                )}
                            </div>
                            <div className="col-6">
                                <span className="lead-text"><span
                                    className=" text-dark extra-small">{(exercisedAmount) ? showRoundAmount(exercisedAmount, toUsdc(targetCurrency)) : 0}</span></span>
                                <span className="lead-text"><span
                                    className=" text-dark extra-small">{(targetCurrency) ? toUsdc(targetCurrency) : "-"}</span></span>
                                {product != null && (
                                    <span className="sub-text">If {product.option.quote_currency}/USD {(product.option.option_type == 'PUT') ? String.fromCharCode(60) : String.fromCharCode(62)} {showPrice(product.option.strike_price, '')}</span>
                                )}
                                {(!product) && (
                                    <span className="sub-text">-</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

        </>
    )
}

export default Component;
