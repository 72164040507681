import {useEffect, useState,useContext} from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

import {Link, useNavigate} from 'react-router-dom';
import Layout from '../../layouts/auth';
import Setup2FA from '../../components/auth/setup2fa';
import Enter2FA from '../../components/auth/enter2fa';
import VerifyEmail from '../../components/auth/verify';
import { Errors } from '../../components/common/msg';

import { post } from '../../libs/api';
import UserContext from "../../libs/context";
// import { setAuthToken } from '../../libs/auth';
import firebaseConfig from '../../config/firebase';
import { EmailField, PasswordField, SelectField, DateField, useValidation } from '../../components/form';

// import dotenv from 'dotenv';

// https://blog.logrocket.com/user-authentication-firebase-react-apps/

import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { GoogleAuthProvider, OAuthProvider, getAuth, signInWithPopup, signInWithEmailAndPassword,
  createUserWithEmailAndPassword, sendPasswordResetEmail, signOut, 
  multiFactor, RecaptchaVerifier, PhoneAuthProvider, PhoneMultiFactorGenerator  } from 'firebase/auth';
import {wait} from "@testing-library/user-event/dist/utils";
import useInterval from "../../libs/useInterval";



function Page() {
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [verificationId, setVerificationId] = useState();
  const [verificationCode, setVerificationCode] = useState();
  const [authUser, setAuthUser] = useState();
  const [tab, setTab] = useState(0);
  const [fbUid, setFbUid] = useState(null);
  const [fbToken, setFbToken] = useState(null);
  const [fbSecret, setFbSecret] = useState(null);
  const [account, setAccount] = useState(null);
  const [errors, setErrors] = useState([]);
  const [notices, setNotices] = useState([]);

  const navigate = useNavigate();
  const {currentUser, login} = useContext(UserContext);
  
  const [showError, setShowError] = useState(false);
  const [setFieldValid, isValid] = useValidation();

  const app = initializeApp(firebaseConfig);
  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA_CONFIG_SITEKEY),
    isTokenAutoRefreshEnabled: true
  });
  const auth = getAuth(app);

  const googleProvider = new GoogleAuthProvider();
  const signInWithGoogle = async () => {
    try {

      setLoading(true);
      const res = await signInWithPopup(auth, googleProvider);
      const user = res.user;
      const uid = user.uid;
      const idToken = await user.getIdToken(false);
      
      setFbUid(uid);
      setFbToken(idToken);
      await afterLogin(idToken, uid);
    } catch (err) {
      setLoading(false);
      console.error(err);
      setErrors([err.message]);
    }
  };

  const signInWithMicrosoft = async () => {
    try {

      setLoading(true);
      const provider = new OAuthProvider('microsoft.com');
      provider.setCustomParameters({
        tenant: process.env.REACT_APP_MICROSOFT_PS_TENANT
      });
      const res = await signInWithPopup(auth, provider);
      const user = res.user;
      const uid = user.uid;
      const idToken = await user.getIdToken(false);

      setFbUid(uid);
      setFbToken(idToken);
      await afterLogin(idToken, uid);
    } catch (err) {
      setLoading(false);
      console.error(err);
      setErrors([err.message]);
    }
  };

  const afterVerify = async(token, status) => {
    // save token
    const expired = (new Date()/1000) + 86400;
    await login(token, expired);
    // if(status != 'VERIFIED'){
    //   navigate('/verification');
    //   return;
    // }
    // navigate('/sqna');

    navigate('/agent');
  }

  const afterLogin = async(idToken, uid) => {
    try{
      // handle verify
      const data = await post('/auth/login', {token: idToken, uid: uid});

      setErrors(['']);

      if(!data.error == ''){
        setLoading(false);
        setErrors([data.error]);
      } else if(!data.data?.verified){
        setTab(3);
      }
      else{
        setFbSecret(data?.data.secret);
        setAccount(data?.data.email);
        setTab(data?.data.mfa ? 1 : 2);
      }
    }
    catch(err){
      setLoading(false);
      setErrors([err.message]);
    }
  }

  const handleLogin = async(e) => {
    if(!isValid()){
      setShowError(true);
      return;
    }

    setErrors([]);
    try{
      setLoading(true);
      const cred = await signInWithEmailAndPassword(auth, email, password);
      const uid = cred.user.uid;
      const idToken = await cred.user.getIdToken();
      setFbUid(uid);
      setFbToken(idToken);
      afterLogin(idToken, uid);
    }
    catch(err){
      console.log('firebase error', err);
      setLoading(false);
      if(err.code == 'auth/wrong-password' || err.code == 'auth/user-not-found'){
        setErrors(['Invalid email or password.']);
      }
    }
  }

  useEffect(() => {
    setLoading(false);
  }, [tab]);


  const checkMtnMsg = async() => {
    const data = await post('/auth/mtn/msg');
    if(data?.data?.mtnmsg){
      setNotices([data?.data?.mtnmsg]);
    } else {
      setNotices([]);
    }
  }

  useEffect(() => {
    checkMtnMsg();
  }, []);

  useInterval(() => {
    checkMtnMsg();
  }, 500000); //5sec


  return (
    <Layout notices={notices}>
      {tab == 0 && (
        <>

          {process.env.REACT_APP_IS_TEST == 'true' && (
              <div className="staging-top-bar">
                <p>This is staging</p>
              </div>
          )}

          <div className="nk-block-head">
            <div className="nk-block-head-content">
              {/*<h4 className="nk-block-title">Login into Account</h4>*/}
              {/*<div className="nk-block-des mt-2">*/}
              {/*  <p>Sign in into your account using your email and passcode.</p>*/}
              {/*</div>*/}
              <Errors errors={errors}/>
            </div>
          </div>

          {loading ? (
              <div className="loader-container text-center">
                <ClipLoader color={'#1f2b3a'} size={50} />
                  <p>Loading, please wait a moment ...</p>
              </div>

          ) : (
              <>
              {process.env.REACT_APP_IS_TEST == 'true' && (
                <div className="is-alter">
                  <div className="form-group">
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="username">Email Address <span
                          className="text-danger">*</span></label>
                    </div>
                    <div className="form-control-wrap">
                      {/* <input name="email" type="email" autoComplete="new-email" className="form-control form-control-lg" id="username"
                      placeholder="Enter your email address" data-msg-email="Enter a valid email." data-msg-required="Required." required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}/> */}
                      <EmailField className="form-control form-control-lg" placeholder="Enter your email address"
                                  value={email} required={true} showError={showError}
                                  onValidate={(valid) => setFieldValid('email', valid)}
                                  onChange={(e) => setEmail(e.target.value)}/>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="passcode">Password <span
                          className="text-danger">*</span></label>
                    </div>
                    <div className="form-control-wrap">
                      <a tabIndex="-1" href="#" className="form-icon form-icon-right passcode-switch"
                         data-target="passcode">
                        <em className="passcode-icon icon-show icon ni ni-eye-off"></em>
                        <em className="passcode-icon icon-hide icon ni ni-eye"></em>
                      </a>
                      <PasswordField className="form-control form-control-lg" placeholder="Enter your password"
                                     value={password} required={true} test={false} minLength={8} showError={showError}
                                     onValidate={(valid) => setFieldValid('password', valid)}
                                     onChange={(e) => setPassword(e.target.value)}/>
                      {/* <input name="password" autoComplete="new-password" type="password" className="form-control form-control-lg" id="passcode"
                      placeholder="Enter your passcode" minLength="6" data-msg-required="Required." data-msg-minlength="At least 6 chars."
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}/> */}
                    </div>
                    <div className="form-control-group d-flex justify-between mt-2 mb-gs">
                      <div className="form-control-wrap">
                        <div className="custom-control custom-checkbox">
                          {/* <input type="checkbox" className="custom-control-input" name="remember" id="remember-me"/>
                            <label className="custom-control-label text-soft" htmlFor="remember-me">Remember Me</label> */}
                        </div>
                      </div>
                      <div className="form-control-link">
                        <Link tabIndex="5" className="link link-primary" to="/password/forget">Forgot Password?</Link>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <button type="button" className="btn btn-lg btn-primary btn-block" onClick={handleLogin}>Login
                    </button>
                  </div>
                </div>
              )}

              {/*{process.env.REACT_APP_IS_TEST == 'true' && (*/}

              {/*  <div className="form-group mt-2">*/}
              {/*    <p> Please sign in with your Google Account</p>*/}
              {/*    <button className="btn btn-lg btn-primary btn-block "*/}
              {/*            onClick={signInWithGoogle}><em className="icon ni ni-google"></em>&nbsp;&nbsp;&nbsp;Sign in with Google*/}
              {/*    </button>*/}
              {/*  </div>*/}
              {/*)}*/}

                <div className="form-group mt-2">
                  <p> Please sign in with your Microsoft Account</p>
                  <button className="btn btn-lg btn-primary btn-block"
                          onClick={signInWithMicrosoft}><em className="icon ni ni-microsoft"></em>&nbsp;&nbsp;&nbsp;Sign in with Microsoft
                  </button>
                </div>

                <div className="form-note-s2 text-center pt-4">
                 New to our platform? <Link to="/register"><strong>Create an account</strong></Link>
                </div>
              </>
          )}
        </>
      )}

      {tab == 1 && (
          <Enter2FA token={fbToken} uid={fbUid} onComplete={afterVerify}/>
      )}

      {tab == 2 && (
          <Setup2FA token={fbToken} uid={fbUid} account={account} secret={fbSecret} onComplete={afterVerify}/>
      )}

      {tab == 3 && (
        <VerifyEmail token={fbToken} uid={fbUid} email={email}/>
      )}



    </Layout>
  )
}

export default Page;
