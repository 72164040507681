import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Layout from "../../layouts/user";

import {
  NumberField,
  TextField,
  CheckField,
  useValidation,
} from "../../components/form";

import { Errors, Successes } from "../../components/common/msg";
import useIndicesStore from "../../../src/stores/useIndicesStore";
import { post } from "../../libs/api";
import {
  showDateTime,
  formatDate,
  showPrice,
  showPercent,
  showDate,
  roundDownAmount,
} from "../../libs/util";
import DropDown from "../../components/form/dropdown";
import CalculatePlanCC from "../../components/invest/calculatecc";
import ClipLoader from "react-spinners/ClipLoader";

import Modal from "react-bootstrap/Modal";
import useInterval from "../../libs/useInterval";

function Page() {
  const REFRESH_INTERVAL = 5; //parseInt(process.env.REACT_APP_COUNTDOWN);
  const CONFIRM_INTERVAL = 30;
  const CANCEL_TIMEOUT = 120000; // 2 minutes in milliseconds

  const { indices } = useIndicesStore();
  const [isTradeMtn, setIsTradeMtn] = useState(false);
  const { targetCurrency, sourceCurrency } = useParams();
  const [balance, setBalance] = useState(0);
  const [title, setTitle] = useState("");
  const [wallet, setWallet] = useState(null);
  const [amount, setAmount] = useState("");
  // const [sourceCurrency, setSourceCurrency] = useState(null);
  const [expiredAmount, setExpiredAmount] = useState(0);

  const [maturity, setMaturity] = useState(null);
  const [maturityOptions, setMaturityOptions] = useState([]);

  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(null);
  const [productStartEndDate, setProductStartEndDate] = useState([]);
  const [errors, setErrors] = useState([]);
  const [errors2, setErrors2] = useState([]);
  const [successes, setSuccesses] = useState([]);

  const [showError, setShowError] = useState(false);
  const [setFieldValid, isValid, removeField] = useValidation();

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [step, setStep] = useState(0);
  const [timer, setTimer] = useState(null);
  const [countdown, setCountdown] = useState(REFRESH_INTERVAL);
  const [countdownInterval, setCountdownInterval] = useState(REFRESH_INTERVAL);
  const [cancelTimeout, setCancelTimeout] = useState(null);

  const [btn, setBtn] = useState("Review");

  const [accept, setAccept] = useState(false);
  const [showTc, setShowTc] = useState(false);
  const [isTermsRead, setIsTermsRead] = useState(false);

  // State for options
  const [options, setOptions] = useState([]);

  const [isFetchingParticipationRate, setIsFetchingParticipationRate] =
    useState(false);
  const [desiredFixedRate, setDesiredFixedRate] = useState("");
  const [participationRate, setParticipationRate] = useState(null);

  const handleAddOption = () => {
    if (options.length < 4) {
      setOptions([
        ...options,
        { type: "", direction: "", marketPrice: "", strike: "" },
      ]);
    }
  };

  const handleRemoveOptions = () => {
    setOptions([]);
  };
  const canAddOption = () => {
    if(desiredFixedRate < product?.calc_apy * 100){
      return true;
    } else {
      return false;
    }
  };

  const handleRemoveOption = (index) => {
    const newOptions = options.filter((_, i) => i !== index);
    setOptions(newOptions);
    removeField(`marketPrice${index}`);
  };

  const handleOptionChange = (index, field, value) => {
    const newOptions = [...options];
    newOptions[index][field] = value;

    if (field === "strike") {
      const strike = parseFloat(value);
      newOptions[index].marketPrice = calPercentFromMarketPrice(strike);
    }

    setOptions(newOptions);
  };

  const calPercentFromMarketPrice = (strike) => {
    if (!product || !indices) return;
    const indexPrice = indices.find((x) =>
      x.name.includes(targetCurrency.toLocaleUpperCase())
    );

    if (!indexPrice) return;
    const pctFromMarketPrice = (strike / indexPrice.price) -1;
    return Math.floor(pctFromMarketPrice * 100);
  };

  const updateStrikePrices = () => {
    if (!indices.length) return;

    const updatedOptions = options.map((option) => ({
      ...option,
      marketPrice: calPercentFromMarketPrice(parseFloat(option.strike)),
    }));
    setOptions(updatedOptions);
  };

  useEffect(() => {
    if (step === 0) {
      updateStrikePrices();
    }
  }, [indices, step]);

  useEffect(() => {
    canAddOption();
  }, [desiredFixedRate]);

  const navigate = useNavigate();

  const checkTradeMtnStatus = async () => {
    const data = await post("/auth/mtn/trade");
    if (data?.data?.trademtn) {
      // it is live
      setIsTradeMtn(false);
      setErrors2([]);
    } else {
      setIsTradeMtn(true);
      setErrors2([
        "Investment application is unavailable. Please try again later.",
      ]);
    }
  };

  const fetchProducts = async (targetCurrency, sourceCurrency) => {
    return await post("/investment/product/list", {
      currency: sourceCurrency,
      sourceCurrency,
      productType: "cc",
    });
  };

  useInterval(() => {
    if (step !== 1) return;
    if (!targetCurrency || !sourceCurrency) {
      return;
    }
    if (countdown == 0) {
      checkTradeMtnStatus();
      reloadProduct();
      setCountdown(countdownInterval);
      updateStrikePrices();
      return;
    }
    setCountdown(countdown - 1);
  }, 1000);

  const loadProducts = async () => {
    setProducts([]);
    setMaturity(null);
    setProduct(null);

    const data = await fetchProducts(targetCurrency, sourceCurrency);
    if (data?.status != 200) {
      console.log("error", data);
      setErrors([data.error]);
      return;
    }

    if (data.data?.length == 0) {
      setErrors(["No matching product. Please choose another plan."]);
      return;
    }

    setProducts(data.data);
  };

  const reloadProduct = async () => {
    const data = await fetchProducts(targetCurrency, sourceCurrency);
    if (data?.status == 200) {
      setProducts(data.data);
    }
  };

  const fetchWallet = async () => {
    const data = await post("/wallet/balance", {});
    let currency = sourceCurrency.toUpperCase();

    if (data?.status == 200) {
      for (const w of data.data) {
        if (w.currency == currency) {
          return w;
        }
      }
    }
    return null;
  };

  useEffect(() => {
    if (step == 0) {
      setCountdownInterval(REFRESH_INTERVAL);
      setCountdown(REFRESH_INTERVAL);
    } else {
      setCountdownInterval(CONFIRM_INTERVAL);
      setCountdown(CONFIRM_INTERVAL);
      // Start the 2-minute timeout
      if (cancelTimeout) {
        clearTimeout(cancelTimeout);
      }
      const timeout = setTimeout(() => {
          setStep(0);
      }, CANCEL_TIMEOUT);
      setCancelTimeout(timeout);

      return () => {
          if (cancelTimeout) {
              clearTimeout(cancelTimeout);
          }
      };
    }
  }, [step]);

  const calculateMaturityDate = (startDate, endDate) => {
    const displayProductEndDate = new Date(endDate);
    displayProductEndDate.setUTCHours(8, 0, 0, 0);

    const tempEndDate = new Date(endDate);
    tempEndDate.setUTCHours(0, 0, 0, 0);

    const tempStartDate = new Date(startDate);
    tempStartDate.setUTCHours(0, 0, 0, 0);

    const displayEndDateUTC = displayProductEndDate.toLocaleDateString(
      "en-US",
      { timeZone: "UTC" }
    );
    const displayEndDateTimeUTC = displayProductEndDate.toLocaleString(
      "en-US",
      { timeZone: "UTC" }
    );

    const differenceInMs = tempEndDate.getTime() - tempStartDate.getTime();
    const differenceInDays = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24));
    const currentDate = new Date();
    const displayStartDateUTC = currentDate.toLocaleString("en-US", {
      timeZone: "UTC",
    });

    return {
      endDate: showDate(displayEndDateUTC),
      daysLeft: differenceInDays,
      startDate: displayStartDateUTC,
      endDateTime: displayEndDateTimeUTC,
    };
  };

  useEffect(() => {
    if (products) {
      const sortedProducts = products.sort((a, b) => a.tenor - b.tenor);
      const list = sortedProducts.map((product, index) => {
        const maturityDate = calculateMaturityDate(
          product.startDate,
          product.endDate
        );
        return {
          name: `${product.tenor} ${
            product.product_ccy === "USD" ? "Day" : "Months"
          }`,
          subtitle: `${maturityDate.endDate} (APR ${showPercent(
            product.calc_apy,2
          )})`,
          tenor: product.tenor,
          id: index + 1,
          endDate: maturityDate.endDate,
          endDateTime: maturityDate.endDateTime,
          daysLeft: maturityDate.daysLeft,
        };
      });
      setMaturityOptions(list);
    }
  }, [products]);

  useEffect(() => {
    if (products && maturity) {
      const selectedProduct = products.find((x) => x.tenor === maturity.tenor);
      const { startDate, endDateTime } = calculateMaturityDate(
        selectedProduct.startDate,
        selectedProduct.endDate
      );
      setProduct(selectedProduct);
      setProductStartEndDate([startDate, endDateTime]);
      if (!desiredFixedRate) {
        setDesiredFixedRate(
          (parseFloat(selectedProduct?.calc_apy) * 100).toFixed(1)
        );
      }
    }
  }, [maturity, product, products]);

  useEffect(() => {
    if (product) {
      setErrors([]);
    }
  }, [product]);

  const onCurrencyChange = async () => {
    // load wallet
    setLoading(true);
    const w = await fetchWallet();
    if (!w) {
      setErrors(["Could not get wallet balances"]);
      setLoading(false);
      return;
    }

    setWallet(w);

    // load products
    await loadProducts();
    // if(timer){
    //   clearInterval(timer);
    // }
    // setTimer(setInterval(reloadProduct, 10000));
    setLoading(false);
  };

  useEffect(() => {
    if (sourceCurrency) {
      onCurrencyChange();
    }
  }, [sourceCurrency]);

  useEffect(() => {
    if (!targetCurrency || !sourceCurrency) {
      setTitle("");
      return;
    }
    let strTitle = "";
    let dst = targetCurrency.toUpperCase();
    let src = sourceCurrency.toUpperCase();
    let subfix = "-P";
    if (dst == "USD" || dst == "USDC") {
      strTitle = src + "/USD";
      subfix = "-C";
    }
    if (src == "USD" || src == "USDC") {
      strTitle = dst + "/USD";
    }
    setTitle("DCI " + strTitle + " " + subfix);
  }, [targetCurrency, sourceCurrency]);

  useEffect(() => {
    setBalance(wallet ? wallet.main_balance : 0);
  }, [wallet]);

  useEffect(() => {
    if (!product) {
      return;
    }

    let value = parseFloat(amount);
    if (isNaN(value)) {
      setExpiredAmount(0);
      return;
    }
    let interest = 0.0;
    const productAPR = desiredFixedRate
      ? desiredFixedRate / 100
      : product.calc_apy;
    interest = parseFloat(
      (
        (parseFloat(maturity.daysLeft) / 365) *
        parseFloat(productAPR) *
        value
      ).toFixed(8)
    );

    setExpiredAmount(value + interest);
  }, [product, amount, desiredFixedRate]);

  const handleAmountChange = (e) => {
    const value = e.target.value;
    const regex = /^\d*(\.\d{0,8})?$/;
    if (regex.test(value)) {
        setAmount(value);
    }
};
  const handleStep1Submit = async (e) => {
    e.preventDefault();
    setErrors([]);
     if (!isValid()) {
      setShowError(true);
      return;
    }

    const value = parseFloat(amount);
    if (value == NaN) {
      setErrors(["Please provide numeric value."]);
      return;
    }

    if (
      String(amount).lastIndexOf(".") >= 0 &&
      String(amount).length - String(amount).lastIndexOf(".") > 9
    ) {
      setErrors(["Please limit to 8 decimal places."]);
      return;
    }
    if (value > wallet?.main_balance) {
      setErrors(["Exceeded wallet balance."]);
      return;
    }
    if (product.min_amount > 0 && value < product.min_amount) {
      setErrors([
        "Amount must be at least " +
          showPrice(product.min_amount, wallet.currency),
      ]);
      return;
    }

    if (product.max_amount > 0 && value > product.max_amount) {
      setErrors([
        "Amount must not exceed " +
          showPrice(product.max_amount, wallet.currency),
      ]);
      return;
    }
    await getParticipationRate();
    setStep(1);
    setIsTermsRead(false);
    // setShowTc(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    if (!isValid()) {
      setShowError(true);
      return;
    }

    if (submitting) {
      return;
    }

    try {
      setSubmitting(true);
      console.log('org expiredAmount: ', expiredAmount)

      // submit an order
      let order = {
        wallet_id: wallet.wallet_id,
        product_id: product.product_id,
        maturity_datetime: formatDate(
          productStartEndDate[1],
          "yyyy-mm-dd HH:MM:ss"
        ),
        invest_amount: parseFloat(amount),
        invest_ccy: wallet.currency,
        term_expired_amount: roundDownAmount(expiredAmount, product.product_ccy),
        term_expired_amount_ccy: product.product_ccy,
        quote_currency: targetCurrency,
        underlying_price: indices.find((x) =>
          x.name.includes(targetCurrency.toLocaleUpperCase())
        ).price,
        term_apy: parseFloat(desiredFixedRate) / 100,
        term_actual_yield: product.actual_yield,
        term_basis: "Upon Maturity",
        tenor: maturity.tenor,
        original_rate: product.calc_apy,
        participation_rate: parseFloat(participationRate) / 100,
        option_list: options.map((option) => ({
          optionType: option.type.id,
          direction: option.direction.id,
          mktPctStrike: parseFloat(option.marketPrice) / 100,
          strikePrice: option.strike,
        })),
      };

      const result = await post("/investment/cc_order/add", order);
      if (result?.status != 200) {
        console.log("list error", result);
        setErrors([result?.error]);
        return;
      }

      setSuccesses([
        "Your order to invest in this product has been submitted.",
      ]);
      setStep(2);
      navigate("/portal/investment/new_order/" + result.data.transaction_id);
    } catch (e) {
      console.log("catch error", e);
    } finally {
      setSubmitting(false);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setStep(0);
  };

  const handleBack = (e) => {
    e.preventDefault();
    navigate("/portal/plans");
  };

  useEffect(() => {
    setBtn(step == 1 ? "Agree to Invest" : "Invest Now");
  }, [step]);

  const handleTcClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setAccept(false);
    setShowTc(false);
    setCountdown(CONFIRM_INTERVAL);
  };

  const handleTcAccept = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (isTermsRead) {
      setAccept(true);
      setShowTc(false);
      setCountdown(CONFIRM_INTERVAL);
    }
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop > e.target.clientHeight / 2;
    if (bottom) {
      setIsTermsRead(true);
    }
  };


  const isValidToGetParticipationRate = () => {
    return (
      amount &&
      product &&
      options.length > 0 &&
      options.every(
        (option) =>
          option.type.id &&
          option.direction.id &&
          option.marketPrice &&
          option.strike
      )
    );
  };

  const getParticipationRate = async () => {
    if (!isValidToGetParticipationRate) return;
    setIsFetchingParticipationRate(true);
    const params = {
      ccy1: targetCurrency,
      ccy2: sourceCurrency,
      ccy2Notional: amount,
      maturityDate: formatDate(productStartEndDate[1], "yyyy-mm-dd"),
      tenor: maturity.tenor,
      psFixedApr: product.calc_apy,
      desiredFixedApr: parseFloat(desiredFixedRate) / 100,
      underlyingPrice: indices.find((x) =>
        x.name.includes(targetCurrency.toLocaleUpperCase())
      ).price,
      options: options.map((option) => ({
        optionType: option.type.id,
        direction: option.direction.id,
        mktPctStrike: parseFloat(option.marketPrice) / 100,
        strikePrice: option.strike,
      })),
    };

      try {
      const data = await post("/investment/participation-rate", params);
      if (data?.status !== 200) {
        setErrors([data.error]);
      } else {
        setParticipationRate(Number((data.data * 100).toFixed(4)));
      }
    } catch (error) {
      setErrors([error.message]);
    } finally {
      setIsFetchingParticipationRate(false);
    }
  };

  return (
    <Layout>
      <div className="page-invest wide-xs m-auto" id="iv-step-container">
        <div className="nk-pps-apps">
          <div className="nk-pps-title text-center">
            <h3 className="title">
              Penguin Secure {sourceCurrency.toUpperCase()} - (with{" "}
              {targetCurrency.toUpperCase(0)} Option)
            </h3>
            {/* <p className="caption-text"></p> */}
            <h1 className="icon-stack">
              <em
                className={
                  "bg-" +
                  sourceCurrency +
                  "-dim icon ni ni-sign-" +
                  sourceCurrency +
                  "-alt"
                }
                title=" "
              ></em>
            </h1>
          </div>

          {successes?.length > 0 && <Successes msgs={successes} />}

          {errors?.length > 0 && <Errors errors={errors} />}

          {isTradeMtn == false && (
            <>
              {step == 0 && (
                <>
                  <div className="form-validate is-alter">
                    <div className="nk-pps-field form-group">
                      <div className="form-label-group">
                        <label className="form-label">Maturity Date</label>
                      </div>
                      <DropDown
                        dtype={"expiry_date"}
                        placeholder="Please choose an Maturity Date"
                        options={maturityOptions}
                        selectedOption={maturity}
                        onChange={(x) => setMaturity(x)}
                      />
                    </div>

                    <div className="nk-pps-field form-group">
                      <div className="form-label-group">
                        <label className="form-label iv-invest-lb-fixed">
                          Investment Amount
                        </label>
                      </div>
                      <div className="form-control-group">
                        <div className="form-info">
                          {sourceCurrency?.toUpperCase()}
                        </div>

                        {!product && (
                          <>
                            <TextField
                              className="form-control form-control-lg form-control-number bg-white"
                              placeholder={"-"}
                              disabled={true}
                              value={null}
                              required={true}
                              showError={showError}
                            />
                          </>
                        )}

                        {product && (
                          <>
                            <NumberField
                              className="form-control form-control-lg form-control-number bg-white"
                              placeholder={""}
                              value={amount}
                              required={true}
                              showError={showError}
                              onValidate={(valid) =>
                                setFieldValid("amount", valid)
                              }
                              onChange={handleAmountChange}
                            />
                          </>
                        )}
                      </div>
                      <div className="form-note-group">
                        {product && (
                          <>
                            <span className="form-note-alt">
                              <a
                                href="#"
                                onClick={(e) => setAmount(wallet.main_balance)}
                              >
                                Wallet Balance:{" "}
                                {showPrice(
                                  wallet.main_balance,
                                  wallet.currency, null, true
                                )}
                              </a>
                              &nbsp;
                              <button
                                className="btn btn-xs btn-primary"
                                onClick={(e) => setAmount(wallet.main_balance)}
                              >
                                MAX
                              </button>
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="nk-pps-field form-group">
                      <div className="form-label-group">
                        <label className="form-label iv-invest-lb-fixed">
                          Desired Fixed Rate
                        </label>
                      </div>
                      <div className="form-control-group w-50">
                        <div className="form-info">%</div>

                        {!product && (
                          <>
                            <TextField
                              className="form-control form-control-sm form-control-number bg-white"
                              placeholder={"-"}
                              disabled={true}
                              value={null}
                              required={true}
                              showError={showError}
                            />
                          </>
                        )}

                        {product && (
                          <>
                            <NumberField
                              className="form-control form-control-sm form-control-number bg-white"
                              placeholder={""}
                              min={0}
                              max={(parseFloat(product.calc_apy) * 100).toFixed(
                                1
                              )}
                              value={desiredFixedRate}
                              required={true}
                              showError={true}
                              onValidate={(valid) =>
                                setFieldValid("desiredFixedRate", valid)
                              }
                              onChange={(e) =>{
                                setDesiredFixedRate(e.target.value)
                                handleRemoveOptions()
                              }}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {!product && (
                    <>
                      <div className="nk-ivp-card card card-bordered card-full mt-4">
                        <div className="card-inner-group">
                          <CalculatePlanCC />
                        </div>
                      </div>
                    </>
                  )}
                  {product && (
                    <div className="nk-ivp-card card card-bordered card-full mt-4">
                      <div className="card-inner-group">
                        <CalculatePlanCC
                          product={product}
                          currency={sourceCurrency}
                          expiredAmount={expiredAmount}
                          daysLeft={maturity.daysLeft}
                          productAPR={desiredFixedRate}
                        />
                      </div>
                    </div>
                  )}
                  {options.length > 0 && (
                    <>
                      <h6 className="nk-pps-title title mt-4 mb-1">Options</h6>

                      <div
                          className="row align-items-center"
                          style={{width: "126%"}}
                      >
                        <div className=" col-md-3">
                          <label className="form-label">Type</label>
                        </div>
                        <div className="col-md-3">
                          <label className="form-label">Direction</label>
                        </div>
                        <div className="col-md-3">
                          <label className="form-label">Strike</label>
                        </div>
                        <div className=" col-md-3">
                          <label className="form-label">
                            From Market Price
                          </label>
                        </div>

                      </div>
                      {options.map((option, index) => (
                          <div
                              key={index}
                              className="nk-pps-field form-group"
                          style={{ width: "120%" }}
                        >
                          <div className="row align-items-center">
                            <div className="option-action">
                              <button
                                  type="button"
                                  className="btn btn-sm btn-danger"
                                  onClick={() => handleRemoveOption(index)}
                              >
                                -
                              </button>
                            </div>
                            <div className="col-md-3 ccplus">
                              <DropDown
                                  dtype={"option_type"}
                                  placeholder="Type"
                                  options={[
                                    {id: "CALL", name: "CALL"},
                                    {id: "PUT", name: "PUT"},
                                  ]}
                                  selectedOption={option.type}
                                  onChange={(value) =>
                                      handleOptionChange(index, "type", value)
                                  }
                              />
                            </div>
                            <div className="col-md-3 ccplus">
                              <DropDown
                                  dtype={"direction"}
                                  placeholder="Direction"
                                  options={[
                                    {id: "LONG", name: "LONG"},
                                    {id: "SHORT", name: "SHORT"},
                                  ]}
                                  selectedOption={option.direction}
                                  onChange={(value) =>
                                      handleOptionChange(index, "direction", value)
                                  }
                              />
                            </div>
                            <div className="col-md-3 ccplus">
                              <NumberField
                                  className="form-control form-control-lg form-control-number bg-white"
                                  value={option.strike}
                                  required={true}
                                  showError={showError}
                                  onChange={(e) =>
                                      handleOptionChange(index,"strike",e.target.value)
                                  }
                              />
                            </div>
                            <div className="col-md-3 ccplus">
                              <div
                                  className="form-info"
                                  style={{
                                    paddingRight: "40px",
                                    paddingLeft: "5px",
                                  }}
                              >
                                %
                              </div>
                              <NumberField
                                  className="form-control form-control-lg form-control-number bg-white"
                                  value={option.marketPrice}
                                  required={true}
                                  disabled={true}
                                  showError={showError}
                                  onValidate={(valid) =>
                                      setFieldValid(`marketPrice${index}`, valid)
                                  }
                              />
                            </div>

                          </div>
                        </div>
                      ))}
                    </>
                  )}

                  {options.length < 4 && (
                      <button
                          type="button"
                          className="btn btn-sm btn-primary mt-2 mb-2"
                          onClick={handleAddOption}
                          disabled={!canAddOption()}
                      >
                        + Add Option
                      </button>
                  )}

                  <div className="nk-pps-field form-group row align-items-center">
                    <div className="form-label-group">
                      <label className="form-label">Participation Rate</label>
                    </div>
                    <div class="nk-ivp-card card card-bordered participation-card ml-4 mt-4">
                      {isFetchingParticipationRate ? (
                        <div className="loader-container text-center">
                          <ClipLoader color={"#1f2b3a"} size={20} />
                        </div>
                      ) : (participationRate !== null &&
                        participationRate !== undefined) ? (
                        `${participationRate}%`
                      ) : (
                        " - "
                      )}
                    </div>
                    <button
                      type="button"
                      className="btn btn-sm btn-primary my-2 ml-2"
                      onClick={getParticipationRate}
                      disabled={!isValidToGetParticipationRate()}
                    >
                      Get Participation Rate
                    </button>
                  </div>

                  {isValidToGetParticipationRate() && participationRate != null && (
                    <div className="nk-pps-field form-action mt-1">
                      <div className="nk-pps-action">
                        <a
                          href="#"
                          className="btn btn-lg btn-block btn-primary"
                          onClick={handleStep1Submit}
                        >
                          <span>Next</span>
                          {isFetchingParticipationRate &&
                            <span className="loader-container text-center"   
                            style={{ marginLeft: "10px", paddingTop: "5px" }}>
                              <ClipLoader color={"#1f2b3a"} size={20} />
                            </span> 
                          }
                        </a>
                      </div>
                    </div>
                  )}
                </>
              )}

              {step == 1 && (
                <>
                  <div className="nk-ivp-card card card-bordered card-full mt-4">
                    <div className="card-inner-group">
                      <div className="pl-2 pr-2 pt-2">
                        <div className="card-title text-center">
                          <span className="title form-label">
                            {countdown} seconds to refresh
                          </span>
                        </div>
                        <div className="nk-ivp-summary card-text">
                          <div className="row">
                            <div className="col-6">
                              <span className="sub-text">Selected Product</span>
                              <span className="lead-text">
                                <span className=" text-dark">
                                  {product?.display_title}
                                </span>
                              </span>
                            </div>
                            <div className="col-6">
                              <span className="sub-text">Invested Amount</span>
                              <span className="lead-text">
                                <span className="text-dark">
                                  {showPrice(parseFloat(amount),sourceCurrency.toUpperCase(), null, true)}
                                </span>
                              </span>
                            </div>
                          </div>

                          {/*<div className="row mt-1 pb-1 border-bottom">*/}
                          {/*  <div className="col-4 text-left pl-4">*/}
                          {/*    <span>Term Duration</span>                            */}
                          {/*  </div>*/}
                          {/*  <div className="col-8 text-right pr-4">                            */}
                          {/*    <span>{product.tenor} Days</span>*/}
                          {/*  </div>*/}
                          {/*</div>*/}
                          <div className="row mt-3 pb-1 border-bottom">
                            <div className="col-4 text-left pl-4">
                              <span>Desired Fixed Rate</span>
                            </div>
                            <div className="col-8 text-right pr-4">
                              <span>
                                {desiredFixedRate
                                  ? desiredFixedRate + "%"
                                  : "-"}
                              </span>
                            </div>
                          </div>
                          <div className="row mt-1 pb-1 border-bottom">
                            <div className="col-4 text-left pl-4">
                              <span>Participation Rate</span>
                            </div>
                            <div className="col-8 text-right pr-4">
                              <span>{participationRate}%</span>
                            </div>
                          </div>
                          <div className="row mt-1 pb-1 border-bottom">
                            <div className="col-4 text-left pl-4">
                              <span>Investment Date</span>
                            </div>
                            <div className="col-8 text-right pr-4">
                              <span>
                                {showDateTime(productStartEndDate[0])}
                              </span>
                            </div>
                          </div>

                          <div className="row mt-1 pb-1 border-bottom">
                            <div className="col-4 text-left pl-4">
                              <span>Maturity Date</span>
                            </div>
                            <div className="col-8 text-right pr-4">
                              <span>
                                {showDateTime(productStartEndDate[1])}
                              </span>
                            </div>
                          </div>

                          <table className="table">
                            <thead>
                              <tr>
                                <th>Type</th>
                                <th>Direction</th>
                                <th>From Market Price</th>
                                <th>Strike</th>
                              </tr>
                            </thead>
                            <tbody>
                              {options.map((option, index) => (
                                <tr key={index}>
                                  <td>{option.type.name}</td>
                                  <td>{option.direction.name}</td>
                                  <td>{option.marketPrice}%</td>
                                  <td>{option.strike}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <CalculatePlanCC
                        product={product}
                        currency={sourceCurrency}
                        expiredAmount={expiredAmount}
                        daysLeft={maturity.daysLeft}
                        productAPR={desiredFixedRate}
                      />
                    </div>
                  </div>

                  {/* <div className="form-group mt-2 mb-1"> */}
                    {/* <div className="custom-control custom-control-xs custom-checkbox"> */}
                    {/* <CheckField
                      className="custom-control-input"
                      requiredMsg=" You must accept our terms to proceed."
                      required={true}
                      showError={showError}
                      onValidate={(valid) => setFieldValid("accept", valid)}
                      checked={accept}
                      onChange={(checked) => setAccept(checked)}
                    >
                      I have read, understood and agree to the Product Fact
                      Sheet.
                    </CheckField> */}

                    {/* </div> */}
                  {/* </div> */}
                  <div className="nk-pps-field form-action">
                    <div className="nk-pps-action">
                      <a
                        href="#"
                        className="btn btn-lg btn-block btn-primary"
                        onClick={handleSubmit}
                      >
                        <span>Agree to Invest ({countdown})</span>
                        {submitting && (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                      </a>
                    </div>
                    <div className="nk-pps-action mt-1">
                      <a
                        href="#"
                        className="btn btn-lg btn-block btn-white"
                        onClick={handleCancel}
                      >
                        <span>Cancel</span>
                      </a>
                    </div>
                  </div>
                  <Modal show={showTc} onHide={handleTcClose}>
                    <div className="modal-header">
                      <div className="modal-title h4">
                        Penguin Secure Product Factsheet
                      </div>
                      <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        onClick={handleTcClose}
                      >
                        X
                      </button>
                    </div>
                    <Modal.Body>
                      <div className="Content" onScroll={handleScroll}>
                        <p>
                          <h5>BRIEF INFORMATION ON THE PRODUCT</h5>

                          <p>
                            <strong>What is this product about?</strong>
                          </p>
                          <p>
                            Penguin Secure is a simple fixed yield investment
                            product over a defined tenor. Principal and Yield
                            are typically paid out on expiry
                          </p>
                        </p>
                        <hr />
                        <p>
                          <h5>PRODUCT SUITABILITY</h5>
                          <p>
                            <strong> Who is this product suitable for?</strong>
                          </p>
                          <p>
                            This product offers *principal protection and
                            guarantees a fixed return on expiry. This is a
                            low-risk product that is market neutral. The
                            investment time horizon here is envisioned to be
                            short term
                          </p>
                        </p>
                        <hr />

                        <p>
                          <h5>KEY PRODUCT FEATURES</h5>

                          <p>
                            <strong>What am I investing in?</strong>
                          </p>
                          <p>
                            This structured product is designed to primarily
                            capitalize on the contango of futures contracts. By
                            employing basis trading strategies, the product aims
                            to generate returns through the difference between
                            spot prices and futures prices, and/or spreads
                            between dated and perpetual futures contracts
                            written on a particular Digital Payment Token (DPT).
                            The choice of Underlying is discretionary to Penguin
                            Securities Trading Pte. Ltd. (PST) but strictly
                            limited to supported assets by the off-exchange
                            settlement solution (OES) and/or select Liquidity
                            Providers (LPs), which may vary from time to time
                          </p>
                          <table className="table">
                            <tbody>
                              <tr>
                                <td>Principal Asset: </td>
                                <td>USDT / USDC</td>
                              </tr>
                              <tr>
                                <td>Minimum Investment: </td>
                                <td>*USDT 100,000 or equivalent</td>
                              </tr>
                              <tr>
                                <td>Underlying: </td>
                                <td>
                                  DPT spot and futures (dated and perpetual)
                                  contracts supported by OES and/or LPs
                                </td>
                              </tr>

                              <tr>
                                <td>Investment Tenure</td>
                                <td>3 – 12 months</td>
                              </tr>
                              <tr>
                                <td>*Principal Protection</td>
                                <td>Yes</td>
                              </tr>
                              <tr>
                                <td>Leverage:</td>
                                <td>No</td>
                              </tr>
                              <tr>
                                <td>Yield/Annual Percentage Rate (APR): </td>
                                <td>Fixed</td>
                              </tr>
                              <tr>
                                <td>Payment Frequency:</td>
                                <td>Upon expiry</td>
                              </tr>
                              <tr>
                                <td>Issue Date/Time:</td>
                                <td>
                                  Subscription date; also refers to the start of
                                  Yield accrual calculation
                                </td>
                              </tr>
                              <tr>
                                <td>Cut-Off time:</td>
                                <td>
                                  1600 (UTC+8); Any subscription at and after
                                  this time will start Yield accrual calculation
                                  the day after
                                </td>
                              </tr>
                              <tr>
                                <td>Maturity Date/Time:</td>
                                <td>
                                  {" "}
                                  Subscription terminates; also refers to the
                                  end of interest accrual calculation. It also
                                  triggers the settlement process whereby the
                                  Principal and Yield are returned
                                </td>
                              </tr>

                              <tr>
                                <td>Yield Calculation:</td>
                                <td>
                                  Principal Amount x APR x Investment Tenor Day
                                  count(actual)/Year Day count (365)
                                </td>
                              </tr>

                              <tr>
                                <td>Payout Asset:</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>Stable coins (DPT) only:</td>
                                <td>USDT/USDC</td>
                              </tr>
                              <tr>
                                <td>Settlement Process:</td>
                                <td>
                                  <table class="  table-borderless">
                                    <tr class="c3">
                                      <td class="c12" colspan="1" rowspan="1">
                                        <p class="c10">
                                          <span class="c2">Principal</span>
                                        </p>
                                      </td>
                                      <td class="c28" colspan="1" rowspan="1">
                                        <p class="c10">
                                          <span class="c2">DPT</span>
                                        </p>
                                      </td>
                                      <td class="c23" colspan="1" rowspan="1">
                                        <p class="c10">
                                          <span class="c2">
                                            USD (if applicable)
                                          </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr class="c3">
                                      <td class="c12" colspan="1" rowspan="1">
                                        <p class="c10">
                                          <span class="c2">Payment Speed</span>
                                        </p>
                                      </td>
                                      <td class="c28" colspan="1" rowspan="1">
                                        <p class="c10">
                                          <span class="c2">Real Time</span>
                                        </p>
                                      </td>
                                      <td class="c23" colspan="1" rowspan="1">
                                        <p class="c10">
                                          <span class="c2">
                                            2 business days{" "}
                                          </span>
                                        </p>
                                      </td>
                                    </tr>
                                    <tr class="c3">
                                      <td class="c12" colspan="1" rowspan="1">
                                        <p class="c10">
                                          <span class="c2">Limitations</span>
                                        </p>
                                      </td>
                                      <td class="c28" colspan="1" rowspan="1">
                                        <p class="c10">
                                          <span class="c2">
                                            Blockchain Congestion
                                          </span>
                                        </p>
                                      </td>
                                      <td class="c23" colspan="1" rowspan="1">
                                        <p class="c10">
                                          <span class="c2">
                                            Banking hours and currency cut offs
                                          </span>
                                        </p>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </p>

                        <hr />

                        <p>
                          <h5>Issuer</h5>
                          <p>
                            <strong> Who am I investing with?</strong>
                          </p>
                          <p>Penguin Securities Trading Pte. Ltd.</p>
                        </p>

                        <hr />
                        <p>
                          <h5>Associated Restrictions</h5>
                          <p>
                            <strong>
                              What are the associated restrictions of this
                              product that I should know?
                            </strong>
                          </p>
                          <ul>
                            <li>
                              This is a non-transferable investment product
                            </li>
                            <li>
                              Investors should hold the product until maturity.
                              Any early redemption (if applicable) requests will
                              be at the discretion of PST, and will likely incur
                              penalties, and/or loss of Yield, which may incur
                              claw backs (if applicable)
                            </li>
                            <li>
                              Subscription in any other asset other than USDT/C
                              will be subject to discretionary conversion set by
                              PST. Such conversions are provided on an ancillary
                              basis and PST does not undertake any guarantee of
                              performance nor availability of the said facility
                            </li>
                            <li>
                              Rollover Yields are solely determined at
                              prevailing market conditions, taken at the start
                              of Rollover subscription
                            </li>
                            <li>
                              Principal and Yield are calculated on DPT basis
                              solely
                            </li>
                            <li>
                              Subscription and Expiry subject to business days
                              only
                            </li>
                          </ul>
                        </p>

                        <hr />
                        <p>
                          <h5>Fees</h5>
                          <p>
                            <strong>
                              What are the fees and charges I must pay?
                            </strong>
                          </p>
                          <p>
                            All fees and charges are imputed within the
                            investment’s structure and return mechanics
                          </p>
                        </p>

                        <hr />
                        <p>
                          <h5>Key Risks </h5>
                          <p>
                            <strong>What are the key risks involved?</strong>
                          </p>
                          <ul>
                            <li>
                              Penguin Secure investors may be exposed to these
                              key risks, but not limited to, Basis Risk, Credit
                              Risk, Default Risk, Legal Risk, Counterparty Risk,
                              Custodian Risk, Market Risk, Liquidity Risk,
                              Operational Risk, Duration Risk, Sovereign Risk,
                              Event Risk, Decoupling Risk, and Taxation Risk
                            </li>
                            <li>
                              Investors subscribing with Fiat and/or any other
                              form of DPTs acknowledges that they additionally
                              underwrite Exchange Rate Risk as the Product and
                              Underlying are exclusively denominated and settled
                              in Stable coins (USDT/C)
                            </li>
                            <li>
                              In spite of all mitigation efforts put in place by
                              PST, there will always be an off chance that the
                              entire investment is derailed by an event or a
                              series of. In such circumstances, PST cannot be
                              held liable for the performance and delivery as
                              prescribed
                            </li>
                            <li>
                              An investor must carefully consider all risk
                              factors before making an investment decision.
                              Prior to subscription, an investor should consult
                              with his own legal, regulatory, tax, financial and
                              accounting advisors to the extent that he
                              considers it necessary
                            </li>
                          </ul>
                        </p>
                        <hr />
                        <p>
                          <p>
                            <h5>Returns Illustration</h5>
                          </p>
                          <p>
                            Underlying Asset: USDT
                            <br />
                            Principal Amount: 1,000,000.00
                            <br />
                            Start Date: 1/4/2024
                            <br />
                            Maturity Date: 30/6/2024
                            <br />
                            Tenor: 90
                            <br />
                            Year Basis: 356
                            <br />
                            APR: 15%
                            <br />
                            <br />
                            Yield: 36,986.30
                            <br />
                            Principal + Yield: 1,036,986.30
                            <br />
                          </p>
                        </p>
                        <hr />
                        <p>
                          <h5>DISCLAIMERS</h5>
                          <p>
                            This document is provided to you for informational
                            purposes only and does not constitute a solicitation
                            or an offer to buy or sell any products of PST.
                            Nothing in this document is to be construed as
                            legal, accounting, tax, financial or investment
                            advice to you. This document does not take into
                            account the specific investment objectives,
                            investment experience, financial situation or
                            particular needs of any particular person. You
                            should independently evaluate the contents of this
                            document and consider the suitability of any product
                            mentioned in this document taking into account your
                            own specific investment objectives, investment
                            experience, financial situation and particular
                            needs. If in doubt about the contents of this
                            document or the suitability of any service or
                            product mentioned in this document, you should
                            obtain independent financial, legal, accounting, tax
                            or other advice from your own financial or other
                            professional advisers before making a commitment to
                            purchase any investment product.
                            <br />
                            <br />
                            This document is intended only for the person to
                            whom it is issued by PST. It may not be published,
                            circulated, reproduced or distributed either in
                            whole, or in part, without our prior written
                            permission. This document is not intended for
                            distribution, publication or use by any person in
                            any jurisdiction outside Singapore or such other
                            jurisdiction as PST may determine in its sole and
                            absolute discretion, where such distribution,
                            publication or use of this document would be
                            contrary to, forbidden or restricted by, applicable
                            law or regulation or would subject PST to any
                            licensing, registration or other requirements in
                            other jurisdictions. This document has not been
                            reviewed by the Monetary Authority of Singapore or
                            any other regulator in any jurisdiction, and PST is
                            not regulated by the Monetary Authority of Singapore
                            or any other regulator in any jurisdiction.
                            <br />
                            <br />
                            This document has been prepared from sources PST
                            believes to be reliable, but we do not guarantee its
                            accuracy or completeness and do not accept liability
                            for any loss arising from its use. PST reserves the
                            right to remedy any errors that may be present in
                            this document.
                            <br />
                            <br />
                            PST and its respective officers, employees, agents
                            and representatives do not make any express or
                            implied representations, warranties or guarantees as
                            to the accuracy, timeliness, completeness or
                            reliability of the information, data or any other
                            contents of this document. PST does not make any
                            guarantee or other promise as to any results that
                            may be obtained from using our services or
                            subscribing for our products. The price and value of
                            investments mentioned and any income that might
                            accrue could fall or rise or fluctuate. Past
                            performance is not a guarantee or indication of
                            future results.
                            <br />
                            <br />
                            To the maximum extent permitted by law, PST shall
                            not be liable for any losses that you may suffer in
                            respect of your investment and/or subscription of
                            our products.
                            <br />
                            <br />
                            If you have any questions regarding this document,
                            please contact our representative.
                            <p class="c10">
                              <span class="c25">
                                * See sections on Associated Restrictions &amp;
                                Key Risks
                              </span>
                            </p>
                            <br />
                          </p>
                        </p>
                      </div>

                      <div className="text-center">
                        <a
                          href="#"
                          className={
                            "btn btn-block " +
                            (isTermsRead ? "btn-primary" : "btn-grey")
                          }
                          onClick={handleTcAccept}
                        >
                          <span>Accept</span>
                        </a>
                      </div>
                    </Modal.Body>
                  </Modal>
                </>
              )}

              {step == 2 && (
                <div className="nk-pps-field form-action">
                  <div className="nk-pps-action">
                    <a
                      href="#"
                      className="btn btn-lg btn-block btn-primary"
                      onClick={handleBack}
                    >
                      <span>Back to Our Investment Products</span>
                    </a>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default Page;
