import { useState } from "react";

export default () => {
  const [validation, setValidation] = useState({});

  const setFieldValid = (field, valid) => {
    validation[field] = valid;
    setValidation({...validation});
  };

  
  const removeField = (fieldPrefix) => {
    setValidation((prev) => {
      const updatedValidation = Object.keys(prev).reduce((acc, key) => {
        if (!key.startsWith(fieldPrefix)) {
          acc[key] = prev[key];
        }
        return acc;
      }, {});
      return updatedValidation;
    });
  };

  const isValid = (f = null) => {
    if(f){
      return validation[f];
    }
    for(const field in validation){
      if(!validation[field]){
        return false;
      }
    }
    return true;
  }

  return [setFieldValid, isValid, removeField];
};
