import {showDateTime} from '../../libs/util';
import {useEffect, useState} from "react";

const Component = ({account, agent, onManage, onKyc}) => {

    const [kycUrl, setKycUrl] = useState(null);
    const [error, setError] = useState("");

    const setKycInfo = (data) => {

        if (data.kyc_user_token) {


            const appId = data.kyc_application_id;
            const token = data.kyc_user_token;
            const url = "https://nodeam.instance.kyc-chain.com/api/v2/applications/" + appId + "?access_token=" + token;
            setKycUrl(url);
            if (token != null) {
                setError('Unable to enrol KYC');
            }
        }
    }

    useEffect(() => {
        setKycInfo(account);
    }, [])

    return (
        <div className="nk-odr-item">
            <div className="nk-odr-col">
                <div className="nk-odr-info">

                    <div className="nk-odr-data">
                        <div className="nk-odr-label">
                            {account?.full_name} ({account?.account_number || account?.client_id})
                        </div>
                        <div className="nk-odr-meta">
                            <span className="date">{account?.email}</span>
                        </div>
                        <div className="nk-odr-meta">
                            <span className="date">Statu:</span>
                            <span className="date">{(account?.status)}</span>
                        </div>
                        <div className="nk-odr-meta">
                            <span className="date">Date of Birth:</span>
                            <span className="date">{account?.dob}</span>
                        </div>

                        <div className="nk-odr-meta">
                            <span className="date">Created On:</span>
                            <span className="date">{showDateTime(account?.createTime)}</span>
                        </div>

                    </div>
                </div>
            </div>
             <div className="nk-odr-col nk-odr-col-action">
                <div className="nk-odr-action">

                    {account.status == "VERIFIED" &&
                        <a href="#" className="btn btn-sm btn-block btn-primary" target="_self"
                           onClick={(e) => {e.preventDefault(); onManage(account?.user_id)}} >Dashboard</a>
                    }

                    {account.status != "VERIFIED" && account.status != "REJECTED" && !kycUrl &&
                        <a href="#" className="btn btn-sm btn-block btn-warning" target="_self"
                           onClick={(e) => {e.preventDefault(); onKyc(account?.user_id)}} >Enroll KYC</a>
                    }

                    {account.status == "REJECTED" &&
                        <span className="text-danger">  Contact team Penguin Securities</span>
                    }

                    {account.status != "VERIFIED" && kycUrl && process.env.REACT_APP_IS_SKIP_KYC == 'true' &&
                        <span className="text-danger">  Waiting Approval</span>
                    }

                    {account.status != "VERIFIED" && kycUrl && process.env.REACT_APP_IS_SKIP_KYC == 'false' &&
                        <a href={kycUrl} className="btn btn-sm btn-block btn-danger" target="_blank">Launch KYC</a>
                    }

                </div>
            </div>

        </div>
    )

}

export default Component;
