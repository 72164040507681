import {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Layout from '../../layouts/agent';
import {post} from '../../libs/api';
import {Errors} from '../../components/common/msg';
import {SelectField} from "../../components/form";
import SubaccountRow from "../../components/auth/subaccountRow";
import ReactPaginate from "react-paginate";
import {fromDate} from "../../libs/util";

function Page() {

    const [active, setActive] = useState('ACTIVE');
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [accounts, setAccounts] = useState(null);
    const [planTotal, setPlanTotal] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [pageOffset, setPageOffset] = useState(0);
    const [sortField, setSortField] = useState('c.client_name');
    const [manageUser, setManageUser] = useState(null);
    const [kycUser, setKycUser] = useState(null);

    const [email, setEmail] = useState('');
    const [tab, setTab] = useState(0);
    const [errors, setErrors] = useState([]);
    const [searchTerm, setSearchTerm] = useState(''); // Search term state
    const navigate = useNavigate();
    const [agentUser, setAgentUser] = useState(null);

    const sortOptions = [
        {value: "c.create_time", label: "Created Date"},
        {value: "c.client_name", label: "Client Name"},
    ];

    const handleKYCVerification = async (userId) => {


    };

    const loadAgent = async () => {
        const data = await post('/profile/get');
        if (data?.status != 200) {
            setErrors([data.error]);
            return;
        }

        if (data.data.status != 'VERIFIED') {
            if (data.data.role != 'agent') {
                navigate('/verification');
            } else {
                navigate('/portal');
            }
            return;
        }

        setAgentUser(data.data);
    };

    useEffect(() => {
        loadAgent();
    }, []);

    useEffect(() => {
        loadPlans();
    }, [active, pageOffset, sortField]);

    const loadPlans = async () => {
        setLoading(true);
        setLoaded(false);
        setAccounts(null);
        setErrors([]);

        const result = await post('/profile/agent/account/list', {
            pageNum: pageOffset + 1,
            pageSize: pageSize,
            sortField,
            sortOrder: "desc",
            searchTerm: `%${searchTerm.trim()}%`
        });

        setLoading(false);
        if (result?.status != 200) {
            setErrors([result.error]);
            return;
        }
        setLoaded(true);
        setAccounts(result.data);
        setPlanTotal(result.total);
        setPageCount(Math.ceil(result.total / pageSize));
    };

    const handlePageChange = async (event) => {
        setPageOffset(event.selected);
    };

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    useEffect(() => {
        if (manageUser != null)
            updateManageUser();
    }, [manageUser]);

    const updateManageUser = async () => {
        const data = await post('/user/agent/manage', {manageUserId: manageUser.user_id, manageClientId: manageUser.client_id, name: manageUser.full_name, email: manageUser.email, status: manageUser.status});
        if (data?.status != 200) {
            setErrors([data.error]);
            return;
        }

        if (data.data.manage_user != null && data.data.manage_user.status == 'VERIFIED') {
            navigate('/portal');
        } else if (data.data.manage_user == null && data.data.status == 'VERIFIED') {
            navigate('/portal');
        }
    };

    useEffect(() => {
        if (kycUser != null)
            updateKYCUser();
    }, [kycUser]);

    const updateKYCUser = async () => {
        const data = await post('/user/agent/kyc', {manageUserId: kycUser.user_id, manageClientId: kycUser.client_id, name: kycUser.full_name, email: kycUser.email, status: kycUser.status});
        if (data?.status != 200) {
            setErrors([data.error]);
            return;
        }
        await delay(1000);

        alert('KYC Enrolled');
        loadPlans();
    };

    const handleSearchClients = () => {
        setPageOffset(0); // Reset pagination when searching
        loadPlans();
    };


    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const onSearchSubmit = () => {
        setPageOffset(0); // Reset pagination when searching
        loadPlans();
    };

    return (
        <Layout>
            <div className="nk-block">
                <div className="nk-block-head-content text-center">
                    <h4 className="nk-block-title">Agent Dashboard</h4>
                </div>
            </div>
            <Errors errors={errors} />

            <div className="nk-block-head">
                <div className="nk-block-head-content">
                    <div className="nk-block-des">
                        <p>Please choose a client to work with.</p>
                    </div>
                </div>
            </div>

            <div className="nk-block nk-block-xs">
                <div className="row mb-3">
                    {/* 左侧：Client 和 Sort by */}
                    <div className="col-md-6 col-xs-12 d-flex align-items-center">
                        <h6 className="nk-block-title">Client ({planTotal})</h6>
                        <div className="ml-3 d-flex align-items-center">
                            <span className="mr-2">Sort by:</span>
                            <SelectField
                                className="form-control"
                                placeholder="Please select"
                                options={sortOptions}
                                value={sortField}
                                onChange={(e) => setSortField(e.target.value)}
                            />
                        </div>
                    </div>
                    {/* 右侧：Search 输入框 */}
                    <div className="col-md-6 col-xs-12 d-flex justify-content-end">
                        <input
                            type="text"
                            className="form-control mr-2"
                            placeholder="Search by Client Name or ID"
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                        <button className="btn btn-primary" onClick={handleSearchClients}>
                            Search
                        </button>
                    </div>
                </div>

                {errors?.length > 0 && <Errors errors={errors} />}

                {loading && (
                    <div className="nk-odr-list is-stretch card card-bordered">
                        <div className="nk-odr-item">
                            <div className="nk-odr-col">Loading...</div>
                        </div>
                    </div>
                )}

                {loaded && (
                    <>
                        {/* 客户列表 */}
                        <div className="nk-odr-list is-stretch card card-bordered mb-2">
                            {(!accounts || accounts?.length === 0) ? (
                                <div className="nk-odr-item">
                                    <div className="nk-odr-col">No Client Accounts found!</div>
                                </div>
                            ) : (
                                accounts.map((t, i) => (
                                    <SubaccountRow
                                        account={t}
                                        agent={agentUser}
                                        key={i}
                                        onManage={() => setManageUser(t)}
                                        onKyc={() => setKycUser(t)}
                                    />
                                ))
                            )}
                        </div>

                        {/* 分页 */}
                        <ReactPaginate
                            previousLabel="Previous"
                            nextLabel="Next"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageChange}
                            containerClassName="pagination"
                            activeClassName="active"
                            forcePage={pageOffset}
                        />
                    </>
                )}
            </div>
        </Layout>
    );



}

export default Page;
