import { useState } from "react"
import { post, getApiUrl} from '../../libs/api';
import { Errors } from '../common/msg'; 

import { TextField, useValidation } from '../../components/form';

const Setup2FA = ({uid, token, secret, account, onComplete}) => {

  const [code, setCode] = useState('');
  const [errors, setErrors] = useState([]);

  const [showError, setShowError] = useState(false);
  const [setFieldValid, isValid] = useValidation();

  const handleSubmit = async(e) => {
    if(!isValid()){
      setShowError(true);
      return;
    }

    if(!code || code.trim().length == 0){
      setErrors(['Please enter code.']);
      return false;
    }
    const data = await post('/auth/match', {uid, token, code: parseInt(code)})
    if(data.status != 200){
      setErrors([data.error]);
      return false;
    }
    onComplete(data.data.token, data.data.status);
    return false;
  }

  return (
    <>
    <div className="nk-block-head text-center">
      <h4 className="nk-block-title">Enable 2FA Authentication</h4>
    </div>
    <div className="nk-block-content">
      {errors?.map(e => {
        return <p className="alert alert-danger p-1 mb-2">{e}</p>
      })}

      <div className="form-validate is-alter mt-3">
        <p><b>Step 1: </b>Install the Microsoft Authenticator app
          <img src="/images/logo-ms-auth.png" srcSet="/images/logo-ms-auth.png" alt="Download Authenticator via Google Play Store" height="30" />
          from <a target="_blank" href="https://play.google.com/store/apps/details?id=com.azure.authenticator">
          <img src="/images/google-play-store.png"
               srcSet="/images/google-play-store.png" alt="Download Authenticator via Google Play Store" height="30" />
          </a>
          <span> or </span>
          <a target="_blank" href="https://apps.apple.com/us/app/icrosoft-authenticator/id983156458">
            <img src="/images/apple-app-store.png"
                 srcSet="/images/apple-app-store.png" alt="Download Authenticator via App Store" height="30" />
          </a>
        </p>

        <div className="divider stretched sm"></div>

        <p><b>Step 2: </b>On Microsoft Authenticator, scan this QR code.<br/><br/>

          <p className="text-center">
            <img src={getApiUrl() + '/auth/qrcode?token='+token+'&uid='+uid} width={120} height={120}/>
          </p>

          <p className="linewordline">
            <b>&nbsp;&nbsp;&nbsp;OR&nbsp;&nbsp;&nbsp;</b>
          </p>


          <p className="text-center">
            Manually enter your Penguin Securities Account details.<br/>

            At Account name field: type in<br/>
          </p>
          <p className="text-center">
            <strong className="text-dark"><b>Penguin Securities ({account})</b></strong>
          </p>

          <p className="text-center">
            At Your key field: copy and paste the below setup key
          </p>

          <p className="text-center">
            <b className="text-dark">{secret}</b>
          </p>




        </p>

        <div className="divider stretched sm"></div>
        <p><b>Step 3: </b>Enter the 6-digit authentication code from Microsoft Authenticator.<br/><br/>

        <div className="row g-2 align-center">
          <div className="col-sm-8">
            <div className="form-group">
              <div className="form-control-wrap">
                {/* <input id="google2fa-code" type="text" className="form-control" name="google2fa_code" 
                  placeholder="Enter the code to verify"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}/> */}
                <TextField className="form-control" placeholder="Authentication code"
                    value={code} required={true} showError={showError} minLength={6}
                    onValidate={(valid) => setFieldValid('code', valid)}
                    onChange={(e) => setCode(e.target.value)}/>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <button type="submit" className="btn btn-block btn-primary"
                onClick={handleSubmit}>Confirm &amp; Enable</button>
            </div>
          </div>
        </div>
        </p>
        <div className="divider stretched md"></div>
        <p className="text-center text-danger"><strong>Attention: </strong>
          You will lose access to your account if you lose your phone, or if you uninstall the Google Authenticator app.
        </p>
      </div>

      <div className="gap gap-lg"></div>
      <p className="text-center text-soft mx-auto w-max-250px"><a target="_blank" href="mailto://support@penguinsecurities.sg">Lost access to your authenticator app?</a></p>
    </div>
  </>
  )
}

export default Setup2FA;
